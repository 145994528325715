<template>
  <div class="icon-bg">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3 4C2.44772 4 2 4.44772 2 5V7C2 7.55228 2.44771 8 3 8H20C20.5523 8 21 7.55228 21 7V5C21 4.44772 20.5523 4 20 4H3ZM10 10C9.44772 10 9 10.4477 9 11V19C9 19.5523 9.44771 20 10 20H20C20.5523 20 21 19.5523 21 19V11C21 10.4477 20.5523 10 20 10H10Z"
        fill="#A7A9C0"
      />
      <rect opacity="0.3" x="2" y="10" width="5" height="10" rx="1" fill="#A7A9C0" />
    </svg>
  </div>
</template>

<script>
export default {
  name: "IconNews",
  mixins: [],
  props: {},
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.icon-bg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  flex-shrink: 0;
  transition: 0.1s;
  svg {
    width: 24px;
    height: 24px;
  }
  @media (hover: hover) {
    &:hover {
      background: #ebf2fa;
      svg {
        path {
          fill: #5551f9;
        }
      }
    }
  }
  &:active {
    background: transparent;
    svg {
      path {
        fill: #5551f9;
      }
    }
  }
}
</style>
